<script setup>
import { ref, watch } from 'vue';
import { trans } from 'laravel-vue-i18n';
import { useToast } from 'vue-toastification';
import { useStaffStore } from '@/stores/staff';
import { useDateFormatter } from '@/composables/dateFormatter';
import { useOptionsStore } from '@/stores/options';
// Stores
const { updateStaffDetails } = useStaffStore();
const { buildingKeys } = useOptionsStore();
// Utils
const toast = useToast();
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const props = defineProps({
    staff: {
        type: Object,
        default: () => null,
    },
});

const form = ref({
    //
});

// Restore staff related data
watch(
    () => props.staff,
    (staff) => {
        form.value = {
            first_name: staff?.first_name,
            last_name: staff?.last_name,
            phone: staff?.phone,
            dob: parseLocalDateFromString(staff?.dob),
            emerg_first_name: staff?.emerg_first_name,
            emerg_last_name: staff?.emerg_last_name,
            emerg_phone: staff?.emerg_phone,
            emerg_email: staff?.emerg_email,
            has_building_keys: staff?.has_building_keys,
            building_keys: staff?.building_keys.map(key => key.pivot.building_key_id),
        };
    },
    { immediate: true },
);

const save = () => {
    // Remove the '-' from phone'
    const phone = form?.value?.phone?.replace(/-/g, '');
    const emergPhone = form?.value?.emerg_phone?.replace(/-/g, '');

    updateStaffDetails({
        hash: props.staff.hash,
        first_name: form.value.first_name,
        last_name: form.value.last_name,
        phone: phone,
        dob: formatDate(form.value.dob),
        emerg_first_name: form.value.emerg_first_name,
        emerg_last_name: form.value.emerg_last_name,
        emerg_phone: emergPhone,
        emerg_email: form.value.emerg_email,
        has_building_keys: form.value.has_building_keys,
        building_keys: form.value.building_keys,
    })
        .then((_) => {
            toast.success(trans('label.saved'));
        })
        .catch((_) => {
            toast.error(trans('label.error'));
        });
};
</script>
<template>
<div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-2/3 p-2">
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg mb-4">
        {{ $t("label.staff_details_title") }}
    </div>
    <StyledInput
        v-model="form.first_name"
        :placeholder="$t('label.first_name')"
        name="first_name"
        type="text"
        :label="$t('label.first_name')" />
    <StyledInput
        v-model="form.last_name"
        :placeholder="$t('label.last_name')"
        name="last_name"
        type="text"
        :label="$t('label.last_name')" />
    <StyledMask
        v-model="form.phone"
        placeholder="###-###-####"
        :options="{ mask: '###-###-####' }"
        name="phone"
        :label="$t('label.phone')" />
    <StyledDatepicker
        v-model="form.dob"
        :placeholder="$t('label.dob')"
        name="dob"
        type="text"
        :label="$t('label.dob')" />
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg mb-4">
        {{ $t("label.emergency_contact_information") }}
    </div>
    <StyledInput
        v-model="form.emerg_first_name"
        :placeholder="$t('label.emerg_first_name')"
        name="emerg_first_name"
        type="text"
        :label="$t('label.emerg_first_name')" />
    <StyledInput
        v-model="form.emerg_last_name"
        :placeholder="$t('label.emerg_last_name')"
        name="emerg_last_name"
        type="text"
        :label="$t('label.emerg_last_name')" />
    <StyledMask
        v-model="form.emerg_phone"
        placeholder="###-###-####"
        :options="{ mask: '###-###-####' }"
        name="emerg_phone"
        :label="$t('label.emerg_phone')" />
    <StyledInput
        v-model="form.emerg_email"
        :placeholder="$t('label.emerg_email')"
        name="emerg_email"
        type="email"
        :label="$t('label.emerg_email')" />
    <v-divider class="col-span-full mb-4" />
    <div class="col-span-full text-lg mb-4">
        {{ $t("label.building_keys") }}
    </div>
    <StyledRoundSwitch
        v-model="form.has_building_keys"
        color="primary"
        inset
        name="has_building_keys"
        :label="$t('label.has_building_keys')" />
    <StyledDropdown
        v-model="form.building_keys"
        class="col-span-full"
        :disabled="!form.has_building_keys"
        :placeholder="$t('label.building_keys')"
        :options="buildingKeys"
        name="building_keys"
        multiple
        :label="$t('label.building_keys')"  />
    <v-divider class="col-span-full mb-4" />
    <StyledButton class="w-1/3" color="primary" @click="save">{{
        $t("label.save")
    }}</StyledButton>
</div>
</template>
