<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { useSupportPlanGoalEventStore } from '@/stores/supportPlanGoalEvent';
import { useOptionsStore } from '@/stores/options';
import { useUserStore } from '@/stores/user';
import { useDateFormatter } from '@/composables/dateFormatter';

const emit = defineEmits(['close-modal']);
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const { storeSupportPlanGoalEvent, updateSupportPlanGoalEvent } = useSupportPlanGoalEventStore();
const {
    timeOptions,
    programOptions
} = storeToRefs(useOptionsStore());

const props = defineProps({
    event: {
        type: Object,
        default: () => ({})
    },

    goal: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const form = ref({
    date: parseLocalDateFromString(props.event.date) ?? new Date(),
});

const supportPlanDefinedOptions = computed(() => {
    return props.goal?.defined_options.map(option => ({
        label: option.defined_criteria_name,
        value: option.id
    }));
});

const save = async () => {
    const action = props.mode === 'Create' ? storeSupportPlanGoalEvent : updateSupportPlanGoalEvent;

    const formData = new FormData();
    formData.append('support_plan_goal_id', props.goal.id);

    for (const key in form.value) {
        formData.append(key, form.value[key]);
    }

    formData.append('date', formatDate(form.value.date));

    if (Array.isArray(notes.value.data)) {
        notes.value.data.forEach((note, index) => {
            formData.append(`notes[${index}][content]`, note.content);
        });
    }

    if (Array.isArray(files.value.data)) {
        files.value.data.forEach((file, index) => {
            formData.append(`files[${index}][file]`, file.file);
            formData.append(`files[${index}][note]`, file.note);
        });
    }

    await action(formData);

    emit('close-modal');
};

// Notes
const { profile } = storeToRefs(useUserStore());

const notes = ref({
    data: [],
    current_page: 1,
    per_page: 10
});

const note = ref('');

const addNote = () => {
    notes.value.data.push({
        content: note.value,
        user: {
            staff: {
                first_name: profile.value.staff.first_name,
                last_name: profile.value.staff.last_name
            }
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
    });

    note.value = '';
};

// Files
const file = ref(null);
const fileNote = ref(null);

const files = ref({
    data: [],
    current_page: 1,
    per_page: 10
});

const addFile = () => {
    files.value.data.push({
        id: files.value.data.length,
        file: file.value,
        note: fileNote.value,
        original_filename: file.value.name,
        mime_type: file.value.type,
        user: {
            staff: {
                first_name: profile.value.staff.first_name,
                last_name: profile.value.staff.last_name
            }
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
    });

    file.value = null;
    fileNote.value = null;
};

const archiveFile = (file) => {
    files.value.data.forEach((item, index) => {
        if (item === file) {
            files.value.data.splice(index, 1);
        }
    });
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Event'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'goal tracking event'}) }}</template>
    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 md:gap-4">
            <StyledInput
                :value="props.goal?.description"
                :disabled="true"
                :placeholder="$t('label.goal_description')"
                name="description" />
            <StyledDatepicker
                v-model="form.date"
                :placeholder="$t('label.date')"
                name="date"
                view-mode="month"
                :label="$t('label.date')" />
            <StyledDropdown
                v-model="form.start_time"
                :options="timeOptions"
                :label="$t('label.start_time')"
                name="start_time" />
            <StyledDropdown
                v-model="form.end_time"
                :options="timeOptions"
                :label="$t('label.end_time')"
                name="end_time" />
            <StyledDropdown
                v-model="form.program_id"
                searchable
                :options="programOptions"
                :label="$t('label.programs')"
                name="programs" />
            <StyledInput
                v-if="props.goal.type.name == 'Measurable Criteria'"
                v-model="form.measurable_goal_criteria_value"
                :placeholder="props.goal.measurable_unit_name"
                name="measurable_goal_criteria_value"
                :label="props.goal.measurable_unit_name" />
            <StyledDropdown
                v-if="props.goal.type.name == 'Defined Criteria'"
                v-model="form.support_plan_defined_option_id"
                clearable
                :placeholder="$t('label.criteria_value')"
                :options="supportPlanDefinedOptions"
                name="support_plan_defined_option_id"
                :label="$t('label.criteria_value')" />
            <StyledDropdown
                v-model="form.was_breakthrough"
                :options="[{ label: $t('label.breakthrough'), value: true }, { label: $t('label.regression'), value: false }]"
                :label="$t('label.flag')"
                name="was_breakthrough" />
            <StyledTextArea
                v-model="form.description"
                class="col-span-full"
                :placeholder="$t('label.description')"
                name="description"
                :label="$t('label.description')" />
            <div class="col-span-full"> 
                <StyledTextArea
                    v-model="note"
                    :placeholder="$t('label.notes')"
                    name="notes"
                    :label="$t('label.notes')" />
                <div class="flex justify-end col-span-full"> 
                    <StyledButton
                        @click="addNote">
                        {{ $t('label.add_note') }}
                    </StyledButton>
                </div>
            </div>
            <DataTable
                class="col-span-full"
                :data="notes"
                :headers="[
                    {
                        title: $t('label.user'),
                        key: 'title',
                        value: (item) => `${item.user.staff.first_name} ${item.user.staff.last_name}`,
                        sortable: false,
                    },
                    {
                        title: $t('label.content'),
                        key: 'type',
                        value: (item) => item.content,
                        sortable: false,
                    },
                    {
                        title: $t('label.created_at'),
                        key: 'created_by',
                        value: (item) => item.created_at.split('T')[0],
                        sortable: false,
                    },
                    {
                        title: $t('label.last_edited_at'),
                        key: 'updated_at',
                        value: (item) => item.updated_at.split('T')[0],
                        sortable: false,
                    },
                ]" />

            <StyledFileSelector
                v-model="file"
                class="col-span-full"
                name="file"
                :label="$t('label.file')"
                show-loading
                outlined
                dense
                @change="(e) => (file.value = e)" />
            <div class="col-span-full"> 
                <StyledTextArea
                    v-model="fileNote"
                    name="note"
                    :label="$t('label.note')" />
                <div class="flex justify-end col-span-full"> 
                    <StyledButton
                        @click="addFile">
                        {{ $t('label.add_file') }}
                    </StyledButton>
                </div>
            </div>
            <DataTable
                class="col-span-full mb-6"
                :data="files"
                :headers="[
                    {
                        title: $t('label.filename'),
                        key: 'original_filename',
                        value: (item) => item.original_filename,
                        sortable: false,
                    },
                    {
                        title: $t('label.user'),
                        key: 'title',
                        value: (item) => `${item.user.staff.first_name} ${item.user.staff.last_name}`,
                        sortable: false,
                    },
                    {
                        title: $t('label.note'),
                        key: 'note',
                        value: (item) => item.note,
                        sortable: false,
                    },
                    {
                        title: $t('label.mime'),
                        key: 'type',
                        value: (item) => item.mime_type.replace('application/', '').replace('image/', '').toUpperCase(),
                        sortable: false,
                    },
                    {
                        title: $t('label.created_at'),
                        key: 'created_at',
                        value: (item) => item.created_at.split('T')[0],
                        sortable: false,
                    },
                ]"
                :actions="[
                    {
                        title: $t('label.archive'),
                        icon: 'mdi-delete-forever-outline',
                        action: (item) => archiveFile(item),
                    },
                ]"
                @table-update="tableUpdate" />
        </div>
    </form>
    <template #footer>
        <StyledSaveButton modal @save="save" />
    </template>
</ModalWrapper>
</template>
